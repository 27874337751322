(function ($, DataTable) {
    "use strict";

    $(document).on('click', '.actions .column-action-trash > a', function (e) {
        var $this = $(this),
            $icon = $this.find('i.zmdi'),
            iconClass = $this.data('icon-class'),
            colorClass = $this.data('color-class'),
            progressIconClass = 'zmdi-hourglass-alt',
            progressColorClass = 'c-gray',
            $row = $this.closest('tr'),
            $table = $this.closest('table'),
            dt = $table.DataTable();

        if($table.hasClass('responsive') && $table.hasClass('collapsed')) {
            $row = $this.closest('tr').prev('[role="row"]');
        }

        $this.attr('disabled', true);
        $icon.removeClass(iconClass)
            .removeClass(colorClass)
            .addClass(progressIconClass)
            .addClass(progressColorClass);

        $.ajax({
            url: $this.data('url'),
            method: 'post',
            data: {
                _token: $this.data('token'),
                _method: 'delete'
            },
            dataType: 'json',
            success: function (response) {
                $row.attr('data-trashed', 'true');
            },
            error: function (response) {
                $row.attr('data-trashed', 'false');
            },
            complete: function () {
                $this.attr('disabled', false);
                $icon.addClass(iconClass)
                    .addClass(colorClass)
                    .removeClass(progressIconClass)
                    .removeClass(progressColorClass);
            }
        });

        e.preventDefault();
    });

    $(document).on('click', '.actions .column-action-restore > a', function (e) {
        var $this = $(this),
            $icon = $this.find('i.zmdi'),
            iconClass = $this.data('icon-class'),
            colorClass = $this.data('color-class'),
            progressIconClass = 'zmdi-hourglass-alt',
            progressColorClass = 'c-gray',
            $row = $this.closest('tr'),
            $table = $this.closest('table'),
            dt = $table.DataTable();

        if($table.hasClass('responsive') && $table.hasClass('collapsed')) {
            $row = $this.closest('tr').prev('[role="row"]');
        }

        $this.attr('disabled', true);
        $icon.removeClass(iconClass)
            .removeClass(colorClass)
            .addClass(progressIconClass)
            .addClass(progressColorClass);

        $.ajax({
            url: $this.data('url'),
            method: 'post',
            data: {
                _token: $this.data('token'),
                _method: 'put'
            },
            dataType: 'json',
            success: function (response) {
                $row.attr('data-trashed', 'false');
            },
            error: function (response) {
                $row.attr('data-trashed', 'true');
            },
            complete: function () {
                $this.attr('disabled', false);
                $icon.addClass(iconClass)
                    .addClass(colorClass)
                    .removeClass(progressIconClass)
                    .removeClass(progressColorClass);
            }
        });

        e.preventDefault();
    });

    $(document).on('click', '.actions .column-action-destroy > a', function (e) {
        var $this = $(this),
            $icon = $this.find('i.zmdi'),
            iconClass = $this.data('icon-class'),
            colorClass = $this.data('color-class'),
            progressIconClass = 'zmdi-hourglass-alt',
            progressColorClass = 'c-gray',
            $row = $this.closest('tr'),
            $table = $this.closest('table'),
            dt = $table.DataTable();

        if($table.hasClass('responsive') && $table.hasClass('collapsed')) {
            $row = $this.closest('tr').prev('[role="row"]');
        }

        $this.attr('disabled', true);
        $icon.removeClass(iconClass)
            .removeClass(colorClass)
            .addClass(progressIconClass)
            .addClass(progressColorClass);

        $.ajax({
            url: $this.data('url'),
            method: 'post',
            data: {
                _token: $this.data('token'),
                _method: 'delete',
                _force: true
            },
            dataType: 'json',
            success: function (response) {
                $row.attr('data-trashed', 'true');

                dt.one('draw.dt', function () {
                    $icon.addClass(iconClass)
                        .addClass(colorClass)
                        .removeClass(progressIconClass)
                        .removeClass(progressColorClass);
                });

                dt.row($row)
                    .remove()
                    .draw(false);

            },
            error: function (response) {
                $row.attr('data-trashed', 'false');
            },
            complete: function () {
                $this.attr('disabled', false);
            }
        });

        e.preventDefault();
    });

})(jQuery, jQuery.fn.dataTable);
