function notify(message, type) {
    jQuery.bootstrapGrowl(message, {
        type: type,
        align: 'center',
        offset: {from: 'top', amount: 86},
        width: 'auto'
    });
}

jQuery(function ($) {

    $('.js-notification').each(function () {
        var $notification = $(this);

        notify($notification.data('message') || $notification.html(), $notification.data('type'));
    });

});
