$.fn.dataTable.ext.buttons.filter = {
    text: 'Filters',
    action: function (e, dt, node, config) {
        $('.dataTableFilter').slideToggle();
    }
};

jQuery(function ($) {
    var table = $('.dataTable').DataTable();
    var $form = $('.dataTableFilter');

    function updateFilterButton($form) {
        var filtersApplied = $form.find('.form-control').filter(function (idx, element) {
            return !!$(element).val();
        }).length;

        var $button = $('.dataTableFilterToggle');

        if (filtersApplied) {
            $button.addClass('bgm-green');
            $button.find('span.count').html(' [' + filtersApplied + ']');
        } else {
            $button.removeClass('bgm-green');
            $button.find('span.count').html('');
        }
    }

    $form.on('submit', function (e) {
        updateFilterButton($form);
        $form.slideUp();
        table.draw();
        e.preventDefault();
    });

    $form.on('click', '[type="reset"]', function (e) {
        $form.get(0).reset();
        $form.find('.selectpicker').selectpicker('refresh');
        updateFilterButton($form);
        $form.slideUp();
        table.draw();
        e.preventDefault();
    });

    $form.on('filterable', function (e, filterable) {
        $.each(filterable, function (idx, filter) {
            switch (filter.type) {
                case 'select':
                    $filter = $('select[data-filter-name="' + filter.name + '"]');
                    $filter.empty();

                    $.each(filter.items, function (idx, item) {
                        $option = $('<option/>');

                        $option.val(item.key).text(item.value);

                        var value = typeof filter.value !== 'undefined' ? filter.value : null;

                        if ($.inArray(item.key, $.makeArray(value)) !== -1) {
                            $option.prop('selected', true);
                        }

                        $option.appendTo($filter);
                    });

                    $filter.selectpicker('refresh');
                    break;
                default:
                    break;
            }
        });
    })
});