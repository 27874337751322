jQuery(document).ready(function ($) {

    $(document).on('reanimate', function (e) {
        var $form = $(this);

        $form.find('.html-editor').each(function () {
            $(this).destroy();
            $(this).summernote({
                lang: $('[data-locale-name]').attr('data-locale-name').replace('_', '-'),
                toolbar: [
                    ['style', ['bold', 'italic', 'underline']],
                    ['para', ['ul', 'ol']]
                ]
            });
        });

        $form.find('.selectpicker').each(function () {
            $(this).selectpicker();
        });

        $form.find('.chosen').each(function () {
            $(this).chosen({
                width: '100%',
                allow_single_deselect: true
            });
        });

        $form.find('.date-picker').each(function () {
            var format = $(this).attr('data-format') || $(this).attr('format') || 'YYYY-MM-DD';

            $(this).datetimepicker({
                format: format
            });
        });

        $form.find('.color-picker').each(function () {
            var $container = $(this).closest('.cp-container'),
                $colorOutput = $container.find('.cp-value');

            $(this).farbtastic({
                callback: $colorOutput
            });
        });

        e.preventDefault();
    });
});