jQuery(function ($) {

    /*
     * Sidebar
     */
    $('body').on('click', '.sub-menu > a', function (e) {
        e.preventDefault();
        $(this).next().slideToggle(200);
        $(this).parent().toggleClass('toggled');
    });

    /**
     * Toggle switch
     */
    $(document).on('click', '.toggle-switch', function () {
        var $input = $(this).find('> input');

        $input.val(+$input.is(':checked'));
    });

    /*-----------------------------------------------------------
    Tooltips
-----------------------------------------------------------*/
    if ($('[data-toggle="tooltip"]')[0]) {
        $('[data-toggle="tooltip"]').tooltip();
    }

    /**
     * Login/Register animation
     */
    if ($('.lc-block')[0]) {
        setTimeout(function () {
            $('.lc-block').addClass('toggled');
        }, 500);
    }

    $('.html-editor').each(function () {
        $(this).destroy();
        $(this).summernote({
            lang: $('[data-locale-name]').attr('data-locale-name').replace('_', '-'),
            height: 250,
            toolbar: [
                ['style', ['bold', 'italic', 'underline']],
                ['para', ['ul', 'ol']]
            ]
        });
    });

    $('.selectpicker').each(function () {
        $(this).selectpicker();
    });

    $('.chosen').each(function () {
        $(this).chosen({
            width: '100%',
            allow_single_deselect: true
        });
    });

    $('.date-picker').each(function () {
        var format = $(this).attr('data-format') || $(this).attr('format') || 'YYYY-MM-DD';

        $(this).datetimepicker({
            format: format
        });
    });

    $('.color-picker').each(function () {
        var $container = $(this).closest('.cp-container'),
            $colorOutput = $container.find('.cp-value');

        $(this).farbtastic({
            callback: $colorOutput
        });
    });

});